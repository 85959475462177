@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-shadow {
    text-shadow: 0px 1px 1px #000;
  }

  @variants responsive {
    /* Hide the default scrollbar for Webkit browsers (Chrome, Safari) */
    .no-scrollbar::-webkit-scrollbar {
      width: 8px; /* Width of the scrollbar */
    }

    /* Customize the scrollbar track for Webkit browsers */
    .no-scrollbar::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      /* background- */
    }

    /* Customize the scrollbar thumb (the draggable part) for Webkit browsers */
    .no-scrollbar::-webkit-scrollbar-thumb {
      background: #888; /* Color of the thumb */
      border-radius: 6px; /* Round the thumb */
    }

    /* Hide scrollbar for IE and Edge */
    .no-scrollbar {
      -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
      scrollbar-width: none; /* Hide scrollbar in Firefox */
    }

    /* Customize scrollbar thumb for Firefox */
    .no-scrollbar::-webkit-scrollbar-thumb {
      background: #888; /* Color of the thumb */
      border-radius: 6px; /* Round the thumb */
    }

    /* Customize scrollbar thumb for IE and Edge */
    .no-scrollbar::-ms-thumb {
      background: #888; /* Color of the thumb */
    }

    /* Customize scrollbar track for IE and Edge */
    .no-scrollbar::-ms-track {
      background: #f1f1f1; /* Background color of the track */
    }
  }
}
@layer base {
  :root {
    --swiper-theme-color: #fff !important;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body *::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  body *::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    /* background- */
  }
  body *::-webkit-scrollbar-thumb {
    background: #a8a8a8; /* Color of the thumb */
    border-radius: 6px; /* Round the thumb */
  }
  body * {
    -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
  }
  body *::-webkit-scrollbar-thumb {
    background: #a8a8a8; /* Color of the thumb */
    border-radius: 6px; /* Round the thumb */
  }
  body *::-ms-thumb {
    background: #a8a8a8; /* Color of the thumb */
  }
  body *::-ms-track {
    background: #a8a8a8; /* Background color of the track */
  }
}
@font-face {
  font-family: "rubik";
  src: url("../public/fonts/rubik/RubikMonoOne-Regular.ttf");
}

@font-face {
  font-family: "proxima";
  src: url("../public/fonts/proxima/ProximaNovaA-Regular.ea830e6b.woff");
}

@font-face {
  font-family: "proxima-bold";
  src: url("../public/fonts/proxima/ProximaNovaA-Semibold.a3357887.woff");
}
